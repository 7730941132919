<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <TitleCard title="Productos" subtitle="Listado de productos"></TitleCard>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="products" class="elevation-0" hide-actions>
          <template slot="items" slot-scope="props">
            <td>
              <v-layout align-center justify-center row>
                <v-flex xs2>
                  <v-avatar :tile="true" :size="42" color="grey lighten-4">
                    <img :src="props.item.photo_small" alt="avatar" />
                  </v-avatar>
                </v-flex>
                <v-flex class="text-xs-left" xs10>
                  <h2 class="body-2" v-html="`${props.item.name} (P${props.item.id})`"></h2>
                  <h2
                    v-for="(atributo, index) in props.item.atributos"
                    :key="index"
                    class="caption"
                    v-html="atributo.name"
                  ></h2>
                  <v-alert :value="props.item.comment !== ''" color="success" outline v-html="props.item.comment">
                  </v-alert>
                  <v-btn class="ml-0" :href="props.item.url" target="_blank" icon title="Vista previa en la web">
                    <v-icon small> link </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-0"
                    :to="{ name: 'product', params: { id: props.item.id } }"
                    icon
                    title="Ver producto en la tienda"
                  >
                    <v-icon small> fas fa-store </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="$route.query['foto-entrega']"
                    :color="props.item.publicada ? 'success' : 'error'"
                    class="ml-0"
                    small
                    depressed
                    @click="publish(order.id, !props.item.publicada, props.item.detalle_id)"
                  >
                    <template v-if="props.item.publicada">
                      <v-icon small left> fas fa-check </v-icon>
                      Publicada
                    </template>
                    <template v-else>
                      <v-icon small left> visibility_off </v-icon>
                      Oculta
                    </template>
                  </v-btn>
                </v-flex>
              </v-layout>
            </td>
            <td class="text-xs-right">{{ props.item.quantity }}</td>
            <td class="text-xs-right">
              {{ props.item.value | toPrice }}
            </td>
            <td class="text-xs-right">
              {{ props.item.total | toPrice }}
            </td>
            <td class="text-xs-right">
              <v-icon
                v-if="user.role.id === 1 || user.role.id === 2"
                small
                class="mr-2"
                @click="updateItem(props.item)"
              >
                edit
              </v-icon>
              <v-icon
                v-if="(user.role.id === 1 || user.role.id === 2) && user.shop.admin"
                small
                @click="deleteItem(props.item)"
              >
                delete
              </v-icon>
            </td>
          </template>
          <template slot="footer">
            <tr v-for="(discount, index) in order.discounts" :key="index">
              <td :colspan="3" class="text-xs-right">
                <strong>{{ discount.name }}</strong>
              </td>
              <td :colspan="1" class="text-xs-right">
                {{ discount.total | toPrice }}
              </td>
              <td :colspan="1" class="text-xs-right">
                <v-icon
                  v-if="user.role.id === 1 || user.role.id === 2"
                  small
                  class="mr-2"
                  @click="updateItem(discount)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="(user.role.id === 1 || user.role.id === 2) && user.shop.admin"
                  small
                  @click="deleteItem(discount)"
                >
                  delete
                </v-icon>
              </td>
            </tr>
            <tr>
              <td :colspan="3" class="text-xs-right">
                <strong> Total productos: </strong>
              </td>
              <td :colspan="1" class="text-xs-right">
                {{ totalProducts | toPrice }}
              </td>
              <td :colspan="1" class="text-xs-right"></td>
            </tr>
            <tr>
              <td :colspan="3" class="text-xs-right">
                <strong> Envío: </strong>
              </td>
              <td :colspan="1" class="text-xs-right">
                {{ order.shipping.value | toPrice }}
              </td>
              <td :colspan="1" class="text-xs-right">
                <v-icon v-if="user.shop.admin" small class="mr-2" @click="updateShipping(order.shipping.value)">
                  edit
                </v-icon>
              </td>
            </tr>
            <tr>
              <td :colspan="3" class="text-xs-right">
                <strong> Total: </strong>
              </td>
              <td :colspan="1" class="text-xs-right">
                {{ order.total | toPrice }}
              </td>
              <td :colspan="1"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-flex>
      <v-dialog v-model="dialog.product" max-width="500px">
        <v-card>
          <TitleDialog :title="'Modificar Producto'"></TitleDialog>
          <v-container class="pa-3" grid-list-lg>
            <v-layout row wrap>
              <v-flex v-if="user.shop.admin" xs12>
                <v-text-field v-model="product.name" label="Nombre del producto"></v-text-field>
              </v-flex>
              <v-flex v-if="user.shop.admin" xs2>
                <v-text-field v-model="product.quantity" label="Cantidad"></v-text-field>
              </v-flex>
              <v-flex v-if="user.shop.admin" xs5>
                <v-text-field v-model="product.value" label="Valor"></v-text-field>
              </v-flex>
              <v-flex v-if="user.shop.admin" xs5>
                <v-text-field v-model="product.total" readonly label="Valor Total"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea v-model="product.comment" label="Comentario" auto-grow></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="greyback" flat @click.native="dialog.product = false"> Volver </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingBtn" :disabled="loadingBtn" color="secondary" flat @click.native="updateItemAction">
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.shipping" max-width="300px">
        <v-card>
          <TitleDialog :title="'Valor de envío'"></TitleDialog>
          <v-container class="pa-3" grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field v-model="shippingCost" label="Valor"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="greyback" flat @click.native="dialog.shipping = false"> Volver </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loadingBtn"
              :disabled="loadingBtn"
              color="secondary"
              flat
              @click.native="changeCostShipping"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.addProduct" max-width="400px" persistent>
        <v-card>
          <TitleDialog
            :title="'Añadir un adicional'"
            close
            :close-action="
              () => {
                dialog.addProduct = false
              }
            "
          ></TitleDialog>
          <v-container class="pa-3" grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12>
                <v-radio-group v-model="newItem.type" row>
                  <v-radio label="Descuento" hide-details :value="0"></v-radio>
                  <v-radio label="Producto" hide-details :value="1"></v-radio>
                </v-radio-group>
              </v-flex>
              <template v-if="newItem.type === 0">
                <v-flex xs12>
                  <v-text-field v-model="newItem.name" label="¿A que se debe el descuento?" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="newItem.value"
                    label="Monto descuento (sin puntos)"
                    hide-details
                    type="number"
                    prepend-inner-icon="attach_money"
                  ></v-text-field>
                </v-flex>
              </template>
              <template v-if="newItem.type === 1">
                <v-flex xs12>
                  <v-autocomplete
                    v-model="newItem.product"
                    :items="productsAll"
                    :item-text="
                      item => {
                        return `${item.name} - P${item.id}`
                      }
                    "
                    flat
                    cache-items
                    hide-no-data
                    hide-details
                    label="Seleccione un producto"
                    return-object
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-textarea v-model="newItem.message" label="Comentarios" auto-grow></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="newItem.quantity" label="Cantidad" hide-details type="number"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="newItem.value"
                    label="Valor unitario"
                    hide-details
                    type="number"
                    prepend-inner-icon="attach_money"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Valor total"
                    :value="newItem.quantity * newItem.value"
                    hide-details
                    prepend-inner-icon="attach_money"
                    readonly
                  ></v-text-field>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="back" flat @click.native="dialog.addProduct = false"> Volver </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" flat @click.native="addProductsAction"> Listo </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-btn fixed dark fab bottom right color="accent" @click="addProducts">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { ORDER, GET_SEARCH_PRODUCTS, TO_POST_ORDER } from '../../config'
import TitleDialog from '../useful/titleDialog.vue'
import TitleCard from '../useful/titleCard.vue'

export default {
  name: 'OrderProducts',
  components: {
    TitleDialog,
    TitleCard
  },
  props: ['order', 'getOrder'],
  data() {
    return {
      headers: [
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Cantidad',
          align: 'right',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Valor',
          align: 'right',
          sortable: false,
          value: 'value'
        },
        {
          text: 'Total',
          align: 'right',
          sortable: false,
          value: 'total'
        },
        { text: '', value: 'actions' }
      ],
      shippingCost: 0,
      products: [],
      productsAll: [],
      product: {
        quantity: 0
      },
      dialog: {
        shipping: false,
        product: false,
        addProduct: false
      },
      loadingBtn: false,
      newItem: {
        type: null,
        name: null,
        quantity: 1,
        value: 0,
        message: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    totalProducts() {
      const totalProducts = this._.sumBy(this.products, 'total')
      const discounts = this._.sumBy(this.order.discounts, 'total')
      return totalProducts + discounts
    }
  },
  watch: {
    order(e) {
      this.products = e.products
    },
    'product.quantity': function changeQuantity(e) {
      this.product.total = e * this.product.value
    },
    'newItem.product': function changeProduct(e) {
      this.newItem.value = e.price.oferta ? e.price.new : e.price.old
    }
  },
  mounted() {
    this.products = this.order.products
    this.$store.dispatch('updateTitle', `Productos - Pedido ${this.order.id}`)
  },
  methods: {
    async publish(id, publish, id_detalle) {
      try {
        await this.$http.post(TO_POST_ORDER, {
          id,
          publish,
          id_detalle
        })
        this.getOrder()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: publish ? 'Publicada' : 'Oculta'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateItemAction() {
      this.loadingBtn = true
      try {
        await this.$http.put(`${ORDER}/${this.order.id}/product`, {
          product: this.product
        })
        this.loadingBtn = false
        this.dialog.product = false
        this.$store.dispatch('changeSnack', { active: true, text: 'Actualizado' })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async deleteItemAction(item) {
      try {
        await this.$http.delete(`${ORDER}/${this.order.id}/product`, {
          data: item
        })
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', { active: true, text: 'Eliminado' })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async addProducts() {
      try {
        this.dialog.addProduct = true
        const res = await this.$http.get(GET_SEARCH_PRODUCTS, {
          params: {
            dateShipping: this.order.shipping.date,
            stateId: this.order.shipping.commune_id,
            schedule: 0
          }
        })
        this.productsAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async addProductsAction() {
      let error = false
      let msj = ''
      if (this.newItem.type === null) {
        error = true
        msj = 'Seleccione un tipo'
      } else if (!this.newItem.name && this.newItem.type === 0) {
        error = true
        msj = 'Agregue un nombre al descuento'
      } else if (!this.newItem.product && this.newItem.type === 1) {
        error = true
        msj = 'Seleccione un producto'
      } else if (!this.newItem.quantity && this.newItem.type === 1) {
        error = true
        msj = 'Cantidad no puede estar vacio'
      }
      if (!error) {
        await this.$http.post(`${ORDER}/${this.order.id}/product`, {
          item: this.newItem
        })
        this.dialog.addProduct = false
        this.getOrder()
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: msj })
      }
    },
    async changeCostShippingAction() {
      this.$store.dispatch('setConfirm', { active: false })
      this.loadingBtn = true
      try {
        await this.$http.post(`${ORDER}/${this.order.id}/cost-shipping`, {
          shippingCost: this.shippingCost
        })
        this.loadingBtn = false
        this.dialog.shipping = false
        this.$store.dispatch('changeSnack', { active: true, text: 'Actualizado' })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    deleteItem(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Esta seguro?',
        description: 'El producto sera eliminado de este pedido',
        action: () => this.deleteItemAction(item)
      })
    },
    updateItem(item) {
      this.dialog.product = true
      this.product = item
    },
    updateShipping(value) {
      this.dialog.shipping = true
      this.shippingCost = value
    },
    changeCostShipping() {
      if (this.shippingCost !== this.order.shipping.value) {
        this.$store.dispatch('setConfirm', {
          active: true,
          title: 'Esta seguro?',
          description: `El valor de envío costara $${this.shippingCost} si confirma esta acción.`,
          action: this.changeCostShippingAction
        })
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: 'El valor de envío es el mismo' })
      }
    }
  }
}
</script>

<style></style>
